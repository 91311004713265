<template>
  <div class="Payment" ref="vlbox">
    <div class="index_main" ref="index_main">
      <div class="index_right">
        <p class="search">
          <el-select
            v-if="permissoin.includes('query')"
            clearable
            v-model="searchForm.state"
            size="mini"
          >
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-if="permissoin.includes('query')"
            type="text"
            clearable
            size="mini"
            v-model="searchForm.name"
            placeholder=" 请输入关键字"
          ></el-input>
          <button
            class="btn1"
            v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2"
            v-if="permissoin.includes('add')"
            @click="modal = 1;modalState = 0;">
            <i class="el-icon-plus"></i>
            新增
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                {{ (currentPage2 - 1) * 10 + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="名称"
              :show-overflow-tooltip="true"
              width="400"
            >
            </el-table-column>
            <el-table-column label="付款类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">采购计划付款 </span>
                <span v-if="scope.row.type == 2">商务计划付款 </span>
                <span v-if="scope.row.type == 3">其他付款</span>
                <span v-if="scope.row.type == 4">供应商付款</span>
              </template>
            </el-table-column>

            <el-table-column prop="userName" label="申请人"> </el-table-column>
            <el-table-column prop="createTime" label="申请日期">
            </el-table-column>
            <el-table-column prop="amount" label="申请金额">
             <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.amount,2)}}</span>
                    </template>
            </el-table-column>
            <el-table-column prop="payAmount" label="付款金额">
             <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.payAmount,2)}}</span>
                    </template>
            </el-table-column>
           <!-- <el-table-column prop="stateS" label="付款状态">
              <template slot-scope="scope">
                <span v-if="scope.row.stateS == 2" class="colorblue"
                  >待付款</span>

                <span v-if="scope.row.stateS == 3" class="colororange"
                  >待审核</span>

                <span v-if="scope.row.stateS == 5" class="colorgreen"
                  >已结清</span>

                <span v-if="scope.row.stateS == 4" class="colorred">驳回</span>

                <span v-if="scope.row.stateS == 1" class="colorgreen"
                  >已完成</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="state" label="付款状态">
              <template slot-scope="scope">
                <span v-if="scope.row.state == 2" class="colorblue"
                  >待付款</span>

                <span v-if="scope.row.state == 3" class="colororange"
                  >待审核</span>

                <span v-if="scope.row.state == 5" class="colorgreen"
                  >已结清</span>

                <span v-if="scope.row.state == 4" class="colorred">驳回</span>

                <span v-if="scope.row.state == 1" class="colorgreen"
                  >已完成</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if="permissoin.includes('query')"
                      icon="el-icon-search"
                      :command="{ num: 20, data: scope.row }"
                      >查看</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-edit-outline"
                      v-if="
                        scope.row.state == 3 && permissoin.includes('examine')
                      "
                      :command="{ num: 3, data: scope.row }"
                      >审核</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-money"
                      v-if="(scope.row.state == 2 && permissoin.includes('pay'))"
                      :command="{ num: 2, data: scope.row }"
                      >付款</el-dropdown-item>
                    <el-dropdown-item
                    icon="el-icon-upload2" 
                    v-if="scope.row.invoiceAnnex == 1&&(iId==scope.row.userId)"
                      :command="{ num: 10, data: scope.row }"
                      >上传发票</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner" v-loading="loading" element-loading-text="数据提交中"
                 element-loading-spinner="el-icon-loading">
        <p class="title">
          <span v-if="modalState == 0">新增付款</span>
          <span v-else>付款详情</span>
          <span @click="closeModel" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <div>
            <p>
              <span class="spaa"><span class="star">* </span>付款类型</span>
              <el-select
                v-model="form.type"
                :disabled="modalState != 0"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.type == 4">
              <span class="spaa"><span class="star">* </span>供应商</span>
              <el-select
                :disabled="modalState != 0"
                clearable
                filterable
                v-model="form.projectId"
                size="mini"
                @change="supplierChange"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option3"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.type == 2">
              <span class="spaa"><span class="star">* </span>项目</span>
              <el-select
                clearable
                filterable
                :disabled="modalState != 0"
                v-model="form.projectId"
                size="mini"
                @change="projectChange"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option6"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.type == 1 && modalState == 0">
              <span class="spaa"><span class="star">* </span>采购计划</span>
              <el-select
                clearable
                filterable
                v-model="form.projectId1"
                @change="procureDetailFormChange"
                value-key="id"
                size="mini"
                placeholder="请下拉查看"
              >
                <el-option
                  v-for="item in option4"
                  :key="item.id"
                  :label="item.productName"
                  :value="item"
                >
                  <p>
                    <span style="margin-right: 12px; font-size: 12px"
                      >{{ item.userName }} · {{ item.createTime }}</span
                    >
                    <span
                      style="margin-right: 12px; color: #333; font-size: 12px"
                      >合同金额:{{ item.total }}</span
                    >
                    <span
                      style="color: #333; margin-right: 12px; font-size: 12px"
                      >尾款:{{ item.balance }}</span
                    ><span style="margin-right: 12px; font-size: 12px"
                      >材料:{{ item.productName }}</span
                    >
                  </p>
                </el-option>
              </el-select>
            </p>
            <el-collapse
              v-model="activeNames"
              v-show="form.type == 1"
              accordion
            >
              <el-collapse-item title="计划明细" name="1">
                <el-divider content-position="left">采购明细</el-divider>
                <div class="jstitle">
                  <span style="color: #000">名称</span>
                  <span style="color: #000">数量</span>
                  <span style="color: #000">单价</span>
                  <span style="color: #000">小计</span>
                </div>
                <div
                  class="jstitle"
                  v-for="item in procureDetailForm.procureDetail"
                  :key="item.name"
                >
                  <span>{{ item.name }}</span>
                  <span>{{ item.quantity }}</span>
                  <span>{{ item.unit }}</span>
                  <span>{{ item.unit * item.quantity }}</span>
                </div>
                <p>
                  <span class="spa">总计</span
                  ><span>{{
                    (procureDetailForm.total||0) - (procureDetailForm.freight||0)
                  }}</span>
                </p>
                <p>
                  <span class="spa">运费</span
                  ><span>{{ procureDetailForm.freight }}</span>
                </p>
                <el-divider content-position="left">其他</el-divider>
                <p>
                  <span class="spa">结算</span
                  ><span v-if="procureDetailForm.settlement == '1'"
                    >先款后货</span
                  >
                  <span v-else-if="procureDetailForm.settlement == '2'"
                    >先货后款</span
                  >
                </p>
                <p>
                  <span class="spa">发票</span
                  ><span v-if="procureDetailForm.bill == '1'">无发票</span>
                  <span v-else-if="procureDetailForm.bill == '2'"
                    >普通发票</span
                  >
                  <span v-else-if="procureDetailForm.bill == '3'"
                    >增值税专用发票</span
                  >
                  <span v-if="procureDetailForm.bill != '1'">
                    · {{ procureDetailForm.taxRate }}%</span
                  >
                </p>
                <p>
                  <span class="spa">摘要</span
                  ><span>{{ procureDetailForm.summary }}</span>
                </p>
              </el-collapse-item>
            </el-collapse>

            <!-- <p v-show="form.type == 4">
              <span class="spa">计划列表</span>
              <el-select
                v-model="form.projectId4"
                value-key="id"
                size="mini"
                placeholder="下拉查看列表明细"
              >
                <el-option
                  v-for="item in option5"
                  :key="item.id"
                  :label="item.productName"
                  :value="item.productName"
                >
                  <span style="margin-right: 12px; font-size: 12px"
                    >{{ item.userName }} · {{ item.createTime }}</span
                  >
                  <span style="margin-right: 12px; color: #333; font-size: 12px"
                    >合同金额:{{ item.total }}</span
                  >
                  <span style="margin-right: 12px; color: #333; font-size: 12px"
                    >材料名:{{ item.productName }}</span
                  ><span style="margin-right: 12px; color: #333; font-size: 12px"
                    >尾款:{{ item.balance }}</span
                  >
                </el-option>
              </el-select>
            </p> -->
            <el-collapse
              v-model="activeNames"
              v-show="form.type == 4"
              accordion
            >
                          <el-collapse-item title="计划明细" name="1">
                <div class="jstitle">
                  <span style="color: #000">名称</span>
                  <span style="color: #000">申请人</span>
                  <span style="color: #000;width:67px" >时间</span>
                  <span style="color: #000">金额</span>
                  <span style="color: #000">尾款</span>
                </div>
                <div
                  class="jstitle"
                  v-for="item in option5"
                  :key="item.id"
                >
                  <span>{{ item.productName }}</span>
                  <span>{{ item.userName }}</span>
                  <span  style="width:67px" >{{ item.createTime }}</span>
                  <span>{{ item.total  }}</span>
                  <span>{{ item.balance   }}</span>
                </div>
              </el-collapse-item>
            </el-collapse>
            <p style="display: flex" v-show="form.type == 4 && modalState == 0">
              <span class="spa">对账单</span>
              <el-upload
                class="upload-demo"
                :action="axiosUrl + '/admin/l/file/uploadPicture'"
                :on-success="handlePreview1"
                :on-remove="handleRemove1"
                :file-list="fileList1"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </p>
            <div v-show="form.type == 4 && modalState != 0" style="display:flex">
              <span class="spa" style="min-width:75px">对账单</span>
              <div>
                 <el-image
                v-for="url in form.accountStatementArr"
                :key="url"
                style="width:100px; height:80px"
                fit="contain"
                :src="url"
                :preview-src-list="form.accountStatementArr"
              >
              </el-image>
              </div>
             
            </div>
             <p v-show="form.type == 1 && modalState != 0">
              <span class="spa" style="min-width:75px">采购合同</span>
              <el-image
                v-for="url in form.contractFileArr"
                :key="url"
                style="width: 100px; height: 80px"
                fit="contain"
                :src="url"
                :preview-src-list="form.contractFileArr"
              >
              </el-image>
            </p>
              <p v-show="form.type == 2&&modalState==0">
              <span class="spaa"><span class="star">* </span>商务计划</span>
              <el-select
                v-model="form.planId"
                value-key="id"
                size="mini"
                @change="swjhCg"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option40"
                  :key="item.id"
                  :label="item.creatorName"
                  :value="item.id"
                >
                  <span style="margin-right: 12px; font-size: 12px"
                    >{{ item.creatorName }} · {{ item.allPrice }}</span>
                  <span style="color: #333; margin-right: 12px; font-size: 12px"
                    >尾款：<span style="color: #d62829;">{{ item.balancePayment}}</span> </span>
                  <span style="color: #333; font-size: 12px"
                    >申请时间：{{ item.createTime }}</span>
                </el-option>
              </el-select>
            </p>
            <p style="display:flex"  v-if="form.invoiceAnnexArr.length==0 && (modalState == 10 || modalState == 0)">
              <span class="spa">发票附件</span>
              <el-upload
                class="upload-demo"
                :action="axiosUrl + '/admin/l/file/uploadPicture'"
                :on-success="handlePreview2"
                :on-remove="handleRemove2"
                :file-list="fileList2"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </p>
            <!-- v-if="(form.type == 1 || form.type == 4) && (modalState != 10 || modalState != 0)" -->
            <div v-if="form.invoiceAnnexArr.length>0"  style="display:flex">
              <span class="spa" style="min-width:75px">发票附件</span>
              <div>
                <el-image
                v-for="url in form.invoiceAnnexArr"
                :key="url"
                style="width: 100px; height: 80px"
                fit="contain"
                :src="url"
                :preview-src-list="form.invoiceAnnexArr"
              >
              </el-image>
              </div>
              
            </div>          
          </div>

          <div style="margin-right: 70px">
            <p v-show="(form.type == 1 || form.type == 2) && modalState != 0">
              <span class="spa">{{
                form.type == 1 ? "采购计划" : "商务计划"
              }}</span
              ><span>{{ form.planUser }} · {{ form.planTime }}</span>
              <span> · {{ form.allPrice }}</span>
            </p>
            <p v-show="form.type == 1 || form.type == 4" class="disabledred">
              <span class="spa">尾款</span>
              <el-input
                disabled
                v-model="form.balance"
                size="mini"
              ></el-input>
            </p>
            <p class="disabledred">
              <span class="spaa"><span class="star">* </span>申请金额</span>
              <el-input
                :disabled="modalState != 0"
                v-model="form.amount"
                size="mini"
              ></el-input>
            </p>
              <p>
              <span class="spaa"><span class="star">* </span>支付方式</span>
              <el-select
                v-model="form.payType"
                :disabled="modalState != 0"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option44"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.payType!=1&&modalState == 0">
              <span class="spa">开户行</span>
              <el-input
                :disabled="modalState != 0"
                v-model="form.openBank"
                size="mini"
              ></el-input>
            </p>

            <p v-show="form.payType!=1&&modalState == 0">
              <span class="spa">账户</span>
              <el-input
                v-model="form.account"
                size="mini"
              ></el-input>
            </p>
            <p v-show="form.payType!=1&&modalState != 0">
              <span class="spa">账户</span>
              <el-input
                disabled
                v-model="form.bankAccount"
                size="mini"
              ></el-input>
            </p>

            <p v-show="form.payType!=1&&modalState == 0">
              <span class="spa">名称</span>
              <el-input
                v-model="form.accountName"
                size="mini"
              ></el-input>
            </p>
            
             <p v-show="form.payType!=1&&modalState != 0">
              <span class="spa">名称</span>
              <el-input
              disabled
                v-model="form.bankName"
                size="mini"
              ></el-input>
            </p>
            <p v-show="form.type == 2 ">
              <span class="spa">联系方式</span>
              <el-input
                :disabled="modalState != 0"
                v-model="form.phone"
                size="mini"
              ></el-input>
            </p>
            <p style="display: flex">
              <span class="spa">付款说明</span>
              <el-input
                type="textarea"
                v-model="form.explain"
                size="mini"
              ></el-input>
            </p>
           

            <div v-show="form.payType != 1">
              <p v-show="form.type == 1 && modalState != 0">
                <span class="spa">单位名称</span>
                <el-input
                  :disabled="modalState != 0"
                  v-model="form.bankName"
                  size="mini"
                ></el-input>
              </p>
              <p v-show="form.type == 1 && modalState != 0">
                <span class="spa">开户行</span>
                <el-input
                  :disabled="modalState != 0"
                  v-model="form.openBank"
                  size="mini"
                ></el-input>
              </p>
              <p v-show="form.type == 1 && modalState != 0">
                <span class="spa">摘要</span>
                <el-input
                  :disabled="modalState != 0"
                  v-model="form.summary"
                  size="mini"
                ></el-input>
              </p>
              <p v-show="form.type == 1 && modalState != 0">
                <span class="spa">支付帐户</span>
                <el-input
                  :disabled="modalState != 0"
                  v-model="form.bankAccount"
                  size="mini"
                ></el-input>
              </p>
            </div>
            <p v-show="modalState == 0">
              <span class="spa">短信提示</span>
              <el-radio-group v-model="form.sms" size="mini">
                <el-radio-button label="1">发送短信</el-radio-button>
                <el-radio-button label="0">不发送</el-radio-button>
              </el-radio-group>
            </p>
   

            <p v-show="modalState == 3 || form.state == 4">
              <span class="spa">审批意见</span>
              <el-input v-model="form.describe" size="mini"></el-input>
            </p>
            <el-divider v-if="form.payInfo.type" content-position="left"
              >支付记录</el-divider
            >
            <div class="item_describe" v-if="form.payInfo.type">
              <div class="describe_right">
                <div class="spa_blue">
                  <!-- <div style='margin-bottom:10px'><i style="font-size:14px" class="el-icon-paperclip" @click="downloadFile(item)"></i>附件{{index+1}}.jpg</div>   -->
                  <p>
                    <span class="spa">支付方式</span
                    ><span>
                      <span v-if="form.payInfo.type == '1'">现金</span>
                      <span v-else-if="form.payInfo.type == '2'">电子汇款</span>
                      <span v-else-if="form.payInfo.type == '3'">对公账户</span>
                    </span>
                  </p>
                  <p>
                    <span class="spa">帐户名称</span
                    ><span>{{ form.payInfo.name }}</span>
                  </p>
                  <p>
                    <span class="spa">银行账户</span
                    ><span>{{ form.payInfo.account.substr(0,4) }}****{{ form.payInfo.account.substr(-4) }}
                      
                    </span>
                  </p>
                  <p>
                    <span class="spa">支付金额</span
                    ><span>{{ form.payInfo.amount }}</span>
                  </p>
                  <p>
                    <span class="spa">支付时间</span
                    ><span>{{ form.payInfo.sdatatime }}</span>
                  </p>
                  <p>
                    <span class="spa" style="min-width:75px">支付凭证</span>
                    <el-image
                      v-for="url in form.payInfo.formFile"
                      :key="url"
                      style="width: 100px; height: 80px"
                      fit="contain"
                      :src="url"
                      :preview-src-list="form.payInfo.formFile"
                    >
                    </el-image>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="item_describe"
              v-show="form.state == 2 && modalState == 2"
            >
              <div class="describe_right">
                <p>
                  <span class="spaa"><span class="star">* </span>支付方式</span>
                  <el-select
                    v-model="zfForm.type"
                    @change="zhChange"
                    size="mini"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in option44"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </p>
                <p>
                  <span class="spaa"><span class="star">* </span>银行账户</span>
                  <el-select
                    v-model="zfForm.bankAccountId"
                    size="mini"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in option33"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                      <span
                        style="margin-right: 12px; color: #333; font-size: 12px"
                        >{{ item.name }}</span
                      >
                      <span
                        style="margin-right: 12px; color: #333; font-size: 12px"
                        >({{ item.account.substr(0,4) }}****{{ item.account.substr(-4) }})</span
                      >
                    </el-option>
                  </el-select>
                </p>
                <p>
                  <!-- <span class="spa">支付金额</span> -->
                  <span class="spaa"><span class="star">* </span>支付金额</span>

                  <el-input v-model="zfForm.amount" size="mini"></el-input>
                </p>
                <p style="display: flex">
                  <span class="spa">支付凭证</span>
                  <el-upload
                    class="upload-demo"
                    :action="axiosUrl + '/admin/l/file/uploadPicture'"
                    :on-success="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="fileList">
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom" v-if="modalState == 0">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button class="btn_gray" @click="closeModel">取消</button>
        </div>
        <div class="bottom" v-if="modalState == 10">
          <button class="btn_red" @click="onsubmit10">上传</button>
          <button class="btn_gray" @click="closeModel">取消</button>
        </div>
        <div class="bottom" v-if="modalState == 3">
          <button class="btn_red" @click="onsubmit1(1)">通过</button>
          <button class="btn_gray" @click="onsubmit1(2)">驳回</button>
        </div>
        <div class="bottom" v-if="modalState == 2">
          <button class="btn_red" @click="payAddF">付款</button>
          <button class="btn_gray" @click="closeModel">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  paymentAdd,fillInvoiceAnnex,
  paymentGetList, procureInfo,
  merchantGetList,
  paymentInfo,
  paymentExamine,
  axiosUrl,
  getListByProcure,
  getListByMerchant,
  businessGetList1,
  wxGetOrgList,
  payAdd,
  getListByPay,
} from "@/api/apis.js";
export default {
  data() {
    return {
      options: [],
      form: { projectId1: {},sms:'0', procureList: [], payInfo: {},invoiceAnnexArr:[],amount:'' },
      info: 1,
      currentPage2: 1,
      axiosUrl,
      treeData: [],
      procureDetailForm: {},
      hide: 1,
      modal: 0,
      treeList: [],
      fileList: [],
      fileList1: [],
      fileList2: [],
      total: 0,
      searchForm: {},
      permissoin: [],
      projectP: -1,
      dialogVisible: false,
      netArrUpdate: [{ id: 0 }],
      ruleForm: {
        pwd: 123456,
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      option2: [
        {
          value: 1,
          label: "采购计划付款",
        },
        {
          value: 2,
          label: "商务计划付款",
        },
        {
          value: 3,
          label: "其他付款",
        },
        {
          value: 4,
          label: "供应商付款",
        },
      ],
      option3: [],
      activeNames: ["1"],
      option33: [],
      option4: [],
      option40: [],
      option44: [
        {
          value: 1,
          label: "现金支付",
        },
        {
          value: 2,
          label: "电子汇款",
        },
        {
          value: 3,
          label: "对公账户",
        },
      ],
      option5: [],
      option6: [],
      option1: [
        {
          value: "3",
          label: "待审核",
        },
        {
          value: "1",
          label: "已完成",
        },
        {
          value: "2",
          label: "待付款",
        },
        {
          value: "4",
          label: "被驳回",
        },
        {
          value: "5",
          label: "已结清",
        },
      ],
      zfForm: {},
      iId:'',
      tableData: [],
      modalState: 0,
      rules: {
        orgId: [{ required: true, message: "选择机构", trigger: "blur" }],
      },
      loading:false,
    };
  },
  methods: {
    getData() {
      let data = {
        state: this.searchForm.state,
        name: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: 10,
      };
      paymentGetList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list
          this.total = res.data.total - 0;
          // console.log(res,res.data,this.tableData);
          // this.tableData.forEach(item=>console.log(item.state))
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
      fmoney(val) {
      if(val){
        //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        var str = val.toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
      }else{
        return '0'
      }    
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    closeModel() {
      this.modal = 0;
      this.procureDetailForm={}
      this.form = { projectId1: {},sms:'0', procureList: [], payInfo: {},invoiceAnnexArr:[],amount:'' };
      this.fileList=[]
          this.fileList1=[]
          this.fileList2=[]
      this.zfForm = {};
      this.option5 = [];
    },
    procureDetailFormChange(v) {
      this.option4.forEach((item) => {
        if (item.id == v.id) {
          this.procureDetailForm = item;
          this.form.balance = item.balance;
          this.form.amount = item.balance;
        }
      });
    },
    //上传文件
    handleRemove(file, fileList) {
      let str = "";
      fileList.forEach((item) => {
        str += item.response.data.url + ",";
      });
      this.zfForm.file = str.slice(0, -1);
    },
    handlePreview(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach((item) => {
          str += item.response.data + ",";
        });
        this.zfForm.file = str.slice(0, -1);
      }
    },
    handleRemove1(file, fileList) {
      let str = "";
      fileList.forEach((item) => {
        str += item.response.data.url + ",";
      });
      this.form.accountStatement = str.slice(0, -1);
    },
    handlePreview1(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach((item) => {
          str += item.response.data + ",";
        });
        this.form.accountStatement = str.slice(0, -1);
      }
    },
    handleRemove2(file, fileList) {
      let str = "";
      fileList.forEach((item) => {
        str += item.response.data.url + ",";
      });
      this.form.invoiceAnnex = str.slice(0, -1);
    },
    handlePreview2(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach((item) => {
          str += item.response.data + ",";
        });
        this.form.invoiceAnnex = str.slice(0, -1);
      }
    },
    supplierChange(v) {
      if (this.form.type == 1) {
        getListByProcure({ merchantId: this.form.projectId }).then((res) => {
          if (res.code == 200) {
            this.option4.forEach((item) => {
              if (item.id == v) {
                this.procureDetailForm = item;
              }
            });
          }
        });
      } else 
      if (this.form.type == 4) {
        getListByMerchant({ merchantId: this.form.projectId }).then((res) => {
          if (res.code == 200) {
            this.option5 = res.data.procureList;
            this.form.balance = res.data.balance;
          } else {
            this.$message.error(res.data.msg);
            this.option5 = [];
            this.form.balance = 0;
          }
        });
      }
    },
    swjhCg(){
        this.option40.forEach(item=>{
          if(item.id==this.form.planId){
            this.form.amount=item.balancePayment
          }
        })
    },
    projectChange() {
      businessGetList1({ projectId: this.form.projectId }).then((res) => {
        if (res.code == 200) {
          this.option40 = res.data;
        } else {
          this.option40 = [];
        }
      });
    },
    onsubmit() {
      let data = {};
      if (this.form.type == 1) {
        data = {
          type: this.form.type,
          amount: this.form.amount,
          explain: this.form.explain,
          payType: this.form.payType,
          invoiceAnnex: this.form.invoiceAnnex,
          // projectId: this.form.projectId,
          planId: this.form.projectId1.id,
          openBank: this.form.openBank,
          account: this.form.account,
          accountName: this.form.accountName,
          sms: this.form.sms,
        };
      } else if (this.form.type == 4) {
        data = {
          type: this.form.type,
          amount: this.form.amount,
          payType: this.form.payType,
          explain: this.form.explain,
          invoiceAnnex: this.form.invoiceAnnex,
          accountStatement: this.form.accountStatement,
          projectId: this.form.projectId,
          sms: this.form.sms,
        };
      } else if (this.form.type == 2) {
        data = {
          type: this.form.type,
          amount: this.form.amount,
          payType: this.form.payType,
          explain: this.form.explain,
          invoiceAnnex: this.form.invoiceAnnex,
          projectId: this.form.projectId,
          planId: this.form.planId,
          openBank: this.form.openBank,
          account: this.form.account,
          accountName: this.form.accountName,
          phone: this.form.phone,
          sms: this.form.sms,
        };
      } else if (this.form.type == 3) {
        data = {
          type: this.form.type,
          payType: this.form.payType,
          invoiceAnnex: this.form.invoiceAnnex,
          amount: this.form.amount,
          explain: this.form.explain,
          sms: this.form.sms,
        };
      }
      let flag = true
      if(!this.form.payType){
        this.$message.error('请填写支付方式')
        flag=false
      }
      if(flag){
        this.loading = true
        paymentAdd(data).then((res) => {
          this.loading = false
        if (res.code == 200) {
          this.getData();
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.modal = 0;
           this.procureDetailForm={}
          this.netArrUpdate = [{ id: 0 }];
          this.fileList=[]
          this.fileList1=[]
          this.fileList2=[]
          this.form = { projectId1: {},sms:'0', procureList: [], payInfo: {},invoiceAnnexArr:[],amount:'' };
        } else {
          this.$message.error(res.data.msg);
        }
      });
      }      
    },
    //进行审批
    onsubmit1(num) {
      let data = {
        state: num,
        describe: this.form.describe,
        id: this.form.id,
      };
      paymentExamine(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getData();
          this.closeModel();
        } else {
          this.$message.error("操作失败");
        }
      });
    },
      //上传发票
    onsubmit10() {
      let data = {
        invoiceAnnex: this.form.invoiceAnnex,
        id: this.form.id,
      };
      fillInvoiceAnnex(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getData();
          this.closeModel();
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    //支付
    payAddF() {
      let data = {
        type: this.zfForm.type,
        bankAccountId: this.zfForm.bankAccountId,
        amount: this.zfForm.amount,
        file: this.zfForm.file,
        mainId: this.form.id,
        mainType: this.form.type - 0 + 1,
        explain: this.form.explain,
      };
      payAdd(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getData();
          this.closeModel();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    zhChange(i) {
      let data = { type: i - 1 };
      getListByPay(data).then((res) => {
        if (res.code == 200) {
          this.option33 = res.data;
        } else {
          this.option33 = [];
        }
      });
    },
    handleCommand(d) {
      this.modalState = d.num;
      let data = { id: d.data.id };
      paymentInfo(data).then((res) => {
        if (res.code == 200) {
          this.form = res.data;
          if (res.data.invoiceAnnex) {
              this.form.invoiceAnnexArr = res.data.invoiceAnnex.split(",");
            } else {
              this.form.invoiceAnnexArr = [];
            }
          if (res.data.payInfo) {
            if (res.data.payInfo.file) {
              this.form.payInfo.formFile = res.data.payInfo.file.split(",");
            } else {
              this.form.payInfo.formFile = [];
            }
          }else{
            this.form.payInfo={}
          }
          if (res.data.type == 4) {
            this.option5 = res.data.procureList;
            
            if (res.data.accountStatement) {
              this.form.accountStatementArr =
                res.data.accountStatement.split(",");
            } else {
              this.form.accountStatementArr = [];
            }
          }
          if (res.data.type == 2) {
            this.projectChange();
          }
          if (res.data.type == 1) {
                    procureInfo({id:res.data.planId}).then((res) => {
              if (res.code == 200) {
                this.procureDetailForm = res.data;
              } else {
                this.$message.error(res.data.msg);
              }
            });
            if (res.data.contractFile) {
              this.form.contractFileArr = res.data.contractFile.split(",");
            } else {
              this.form.contractFileArr = [];
            }
          }
          this.modal = 1;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  mounted() {
    this.getData();
    merchantGetList({ pageSize: 0 }).then((res) => {
      if (res.code == 200) {
        this.option3 = res.data.list.filter((item) => item.type == 1);
      }
    });
    wxGetOrgList({ pageSize: 0 }).then((res) => {
      if (res.code == 200) {
        this.option6 = res.data.list;
      }
    });
     getListByProcure({ merchantId: this.form.projectId }).then((res) => {
          if (res.code == 200) {
            this.option4 = res.data;
          } else {
            this.option4 = [];
          }
        });
    //获取权限
    let arr = JSON.parse(localStorage.getItem("perArr"));
   this.iId= localStorage.getItem("ld"); 
    let routePath = this.$route.path;
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
  },
};
</script>

<style lang="less" >
.Payment {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }

  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 900px;

      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .el-select-dropdown__list {
        max-width: 600px;
      }
      .el-textarea {
        width: 270px;
      }
      .el-textarea__inner {
        width: 270px;
        height: 100px;
      }

      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        height: 700px;
        overflow: auto;
        // padding: 0 34px 20px 34px ;
        padding: 0 34px ;
        // box-sizing: border-box;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .jstitle {
          margin-bottom: 12px;
          span {
            display: inline-block;
            margin-right: 10px;
          }
          & > span:nth-child(1) {
            width: 100px;
          }
          & > span:nth-child(2) {
            width: 50px;
          }
          & > span:nth-child(3) {
            width: 50px;
          }
          & > span:nth-child(4) {
            width: 50px;
          }
          & > span:nth-child(5) {
            width: 50px;
          }
        }
        .el-collapse-item__header {
          width: 350px;
          height: 28px;
          line-height: 28px;
          border: none;
          font-size: 12px;
          color: #000;
        }
        .el-collapse {
          border: none;
          margin-bottom: 10px;
          width: 350px;
          margin-top: -10px;
        }
        .el-collapse-item__content {
          padding-bottom: 0px;
          width: 376px;
          font-size: 12px;
          line-height: 26px;
          color: #999;
          p{
            margin-bottom: 10px;
          }
        }
        .sel {
          display: flex;
          margin-bottom: 14px;
          line-height: 26px;
          .el-input__inner {
            width: 200px;
          }
          .el-select .el-input {
            width: 200px;
          }
        }
        .blue {
          color: #0090ff !important;
          font-weight: bold;
        }
        .red {
          color: #ff2825 !important;
          font-weight: bold;
        }
        .manyitem {
          color: #000;
          margin-bottom: 14px;

          .title1 {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 14px;
            p {
              font-weight: bold;
            }
          }
        }
        .el-table th {
          background-color: white;
          padding: 0px;
        }
        .twoitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .spa {
          display: inline-block;
          width: 72px;
          color: rgba(0, 0, 0);
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 348px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-input {
            width: 270px;
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 348px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        width: 100%;
        margin-top: 10px;
        height: 60px;
        display: flex;
        justify-content: center;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
    .inner1 {
      width: 580px;
      height: 890px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .modal_item {
        padding: 0 30px;
        color: #999;
        .spa {
          display: inline-block;
          width: 75px;
          color: rgba(0, 0, 0);
        }
        .spa_green {
          color: #15bb9b;
          font-weight: bold;
        }
        .passbtn {
          width: 180px;
          height: 35px;
          background-color: #141414;
          color: #fff;
          border-radius: 7px;
          cursor: pointer;
        }
        .refusebtn {
          width: 180px;
          height: 35px;
          background-color: #fe0000;
          color: #fff;
          border-radius: 7px;
          margin-left: 50px;
          cursor: pointer;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        p {
          display: flex;
          margin-bottom: 22px;
          .el-input__inner {
            width: 446px;
          }
        }
        // .seat{
        //   width: auto;
        //   height: 1px;
        //   background-color: #ddd;
        //   margin-bottom: 10px;
        // }
        .item_center {
          display: flex;
          margin-bottom: 22px;
          .spa {
            display: inline-block;
            width: 75px;
          }
          .el-input {
            width: 200px;
          }
        }
        .item_center1 {
          display: flex;
          position: absolute;
          bottom: 28px;
          .spa {
            display: inline-block;
            width: 75px;
          }
          .el-input {
            width: 200px;
          }
        }
        .item_describe {
          height: 216px;
          margin-bottom: 16px;
          overflow: auto;
          flex-wrap: wrap;
          .describe_right {
            .spa_blue {
              color: #0090ff;
              cursor: pointer;
            }
          }
        }
      }
      .foot {
        font-size: 12px;
        margin-top: 18px;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        & > button:nth-child(1) {
          width: 526px;
          height: 40px;
          border-radius: 6px;
          background-color: #141414;
          color: #fff;
          cursor: pointer;
        }
        & > button:nth-child(2) {
          width: 526px;
          height: 40px;
          border-radius: 6px;
          background-color: #fe0000;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>